import { Locale } from '../../domain/Translation';

export const getLocaleUrl = (route: string, locale: Locale) =>
  route.replace(':locale', locale);

export const getYoutubeVideoKeyFromUrl = (url: string) => {
  const regex =
    // eslint-disable-next-line max-len
    /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?youtube\.com\/(?:shorts\/([a-zA-Z0-9_-]{11})|watch\?v=([a-zA-Z0-9_-]{11}))(?:&.*)?$/;

  const match = url.match(regex);

  return match && (match[1] ?? match[2] ?? null);
};
